export const formLocalization = {
  deleteDraftTitle: {
    en: 'Are you sure you want to delete the draft?',
    nb: 'Er du sikker på at du vil slette utkastet?',
    nn: 'Er du sikker på at du vil slette utkastet?',
  },
  deleteDraftConfirmText: {
    en: 'Yes',
    nb: 'Ja',
    nn: 'Ja',
  },
  deleteDraftCancelText: {
    en: 'Cancel',
    nb: 'Avbryt',
    nn: 'Avbryt',
  },
  previousLabel: {
    en: 'Previous',
    nb: 'Forrige',
    nn: 'Førre',
  },
  nextLabel: {
    en: 'Next',
    nb: 'Neste',
    nn: 'Neste',
  },
  submitLabel: {
    en: 'Submit',
    nb: 'Send inn',
    nn: 'Send inn',
  },
  saveAndClose: {
    en: 'Save and close',
    nb: 'Lagre og lukk',
    nn: 'Lagre og lukk',
  },
  deleteDraft: {
    en: 'Delete draft',
    nb: 'Slett utkast',
    nn: 'Slett utkast',
  },
  delete: {
    en: 'Delete',
    nb: 'Slett',
    nn: 'Slett',
  },
  yes: {
    en: 'Yes',
    nb: 'Ja',
    nn: 'Ja',
  },
  no: {
    en: 'No',
    nb: 'Nei',
    nn: 'Nei',
  },
  noFilesUploaded: {
    en: 'No attachments.',
    nb: 'Ingen vedlegg.',
    nn: 'Ingen vedlegg.',
  },
  requiredFieldErrorMessage: {
    en: 'This field is required.',
    nb: 'Dette feltet er påkrevd.',
    nn: 'Dette feltet er påkrevd.',
  },
  maxLengthErrorMessage: {
    en: 'The content of the field is too long',
    nb: 'Innholdet i felter er for langt',
    nn: 'Innhaldet i feltet er for langt',
  },
  submissionSuccessTitle: {
    en: 'Submission was successful!',
    nb: 'Innsendingen var vellykket!',
    nn: 'Innsendinga var vellukka!',
  },
  submissionSuccessMessage: {
    en: 'Your application for release of collaterals has been submitted. We will process your application as soon as possible and will contact you if any necessary documentation is missing. A decision will be sent once the case is decided.',
    nb: 'Din søknad om frafall av sikkerhet er innsendt. Vi vil behandle din søknad så snart som mulig, og tar kontakt dersom nødvendig dokumentasjon mangler. Vedtak vil bli oversendt når saken er besluttet.',
    nn: 'Din søknad om fråfall av tryggleik er sendt inn. Vi vil behandle søknaden din så snart som mogleg og tar kontakt dersom nødvendig dokumentasjon manglar. Vedtak vil bli oversendt når saka er avgjort.',
  },
  backButton: {
    en: 'Back to account overview',
    nb: 'Tilbake til kontooversikt',
    nn: 'Tilbake til kontooversikt',
  },
  submittingFormTitle: {
    en: 'Submitting form...',
    nb: 'Sender inn skjema...',
    nn: 'Sender inn skjema...',
  },
  errorTitle: {
    en: 'Something went wrong',
    nb: 'Noe gikk feil',
    nn: 'Noko gjekk gale',
  },
  errorMessage: {
    en: 'Please try again. If the problem persists, you can contact support at',
    nb: 'Vennligst prøv igjen. Dersom problemet vedvarer kan du ta kontakt med support på',
    nn: 'Ver venleg og prøv igjen. Dersom problemet held fram, kan du kontakte support på',
  },
} as const;
