export const loanDetails = {
  // For balance view

  loanTitle: {
    en: 'Loan',
    nb: 'Lån',
    nn: 'Lån',
  },
  loanSubTitle: {
    en: 'Reference',
    nb: 'Referanse',
    nn: 'Referanse',
  },
  balanceHeader: {
    en: 'Loan',
    nb: 'Lån',
    nn: 'Lån',
  },
  amount: {
    en: 'Book balance:',
    nb: 'Bokført saldo:',
    nn: 'Bokført saldo:',
  },
  availableAmount: {
    en: 'Unused loan:',
    nb: 'Ubenyttet lån:',
    nn: 'Ubrukt lån:',
  },
  amountIsDisbursedFooter: {
    en: 'The loan is disbursed.',
    nb: 'Lånet er utbetalt.',
    nn: 'Lånet er utbetalt.',
  },
  balanceFooter: {
    en: 'The loan is disbursed according to current agreement.',
    nb: 'Lånet utbetales etter gjeldende avtale.',
    nn: 'Lånet vert utbetalt etter gjeldande avtale.',
  },
  warningFooter: {
    en: 'Applications approved prior to 1 January 2018 are under normal circumstances not available for disbursement.',
    nb: 'Tilsagn som er innvilget før 1. januar 2018 vil normalt ikke kunne utbetales lenger.',
    nn: 'Tilsagn som er innvilga før 1. januar 2018 vil vanligvis ikkje bli utbetalt.',
  },
  warningWhenApproved: {
    en: 'The loan has not been disbursed. The information is displayed as in the offer letter and will be updated upon payment.',
    nb: 'Lånet er ikke utbetalt. Informasjonen vises som i tilbudsbrev og blir oppdatert ved utbetaling.',
    nn: 'Lånet er ikkje utbetalt. Informasjonen blir vist som i tilbodsbrev og blir oppdatert ved utbetaling.',
  },
  writtenOff: {
    nb: 'Tapsført',
    nn: 'Tapsført',
    en: 'Written off',
  },

  // For the message beneath balance

  balanceViewMessage: {
    en: 'All amounts are in Norwegian kroner.',
    nb: 'Alle beløp er i norske kroner.',
    nn: 'Alle beløp er i norske kroner.',
  },

  // for next due view

  nextDueHeader: {
    en: 'Next payment due',
    nb: 'Neste forfall',
    nn: 'Neste forfall',
  },
  nextDueFooter: {
    en: 'Amounts may vary with changes in interest rate and other costs.',
    nb: 'Beløpet kan variere med renter eller andre omkostninger.',
    nn: 'Beløpet kan variere om renta eller andre kostnader endrar seg.',
  },

  // for details view

  detailsHeader: {
    en: 'Other details',
    nb: 'Andre detaljer',
    nn: 'Andre detaljar',
  },
  approveDate: {
    en: 'Date granted:',
    nb: 'Innvilget dato:',
    nn: 'Innvilga dato:',
  },
  totalAmount: {
    en: 'Amount granted:',
    nb: 'Innvilget beløp:',
    nn: 'Innvilga beløp:',
  },
  payoutAmount: {
    en: 'Amount disbursed:',
    nb: 'Utbetalt beløp:',
    nn: 'Utbetalt beløp:',
  },
  transferredLoanAmount: {
    en: 'Transferred loans:',
    nb: 'Overførte lån:',
    nn: 'Overførte lån:',
  },
  blockedAmount: {
    en: 'Amount annulled:',
    nb: 'Annullert beløp:',
    nn: 'Annullert beløp:',
  },
  payMethod: {
    en: 'Type of loan:',
    nb: 'Lånetype:',
    nn: 'Lånetype:',
  },
  serial: {
    en: 'Serial loan',
    nb: 'Serielån',
    nn: 'Serielån',
  },
  annuity: {
    en: 'Annuity loan',
    nb: 'Annuitetslån',
    nn: 'Annuitetslån',
  },
  interestType: {
    en: 'Interest type:',
    nb: 'Rentetype:',
    nn: 'Rentetype:',
  },
  interestRate: {
    en: 'Current nominal interest rate:',
    nb: 'Nominell rente p.t.:',
    nn: 'Nominell rente p.t.:',
  },
  effectiveRate: {
    en: 'Current effective interest rate:',
    nb: 'Effektiv rente p.t.:',
    nn: 'Effektiv rente p.t.:',
  },
  periodLength: {
    en: 'Length of installment:',
    nb: 'Terminlengde:',
    nn: 'Terminlengde:',
  },
  periodFee: {
    en: 'Invoice fee:',
    nb: 'Termingebyr:',
    nn: 'Termingebyr:',
  },
  openFee: {
    en: 'Establishment fee:',
    nb: 'Etableringsprovisjon:',
    nn: 'Etableringsprovisjon:',
  },
  matureDate: {
    en: 'Maturity date:',
    nb: 'Siste termin:',
    nn: 'Siste termin:',
  },
  accountName: {
    en: 'Loan number:',
    nb: 'Lånenummer:',
    nn: 'Lånenummer:',
  },
  percentPerYear: {
    en: '% per year',
    nb: '% per år',
    nn: '% per år',
  },
  detailsFinalMessage: {
    en: '* The stated nominal interest rate may deviate from the prevailing interest rate. See the latest interest rate change letter for the correct interest rate.',
    nb: '* Den oppgitte nominelle renten kan være avvikende fra gjeldende rente. Se siste renteendringsbrev for riktig rentesats.',
    nn: '* Den oppgitte nominelle renta kan vera avvikande frå gjeldande rente. Sjå siste renteendringsbrev for rett rentesats.',
  },

  // For code

  unknownDate: {
    en: 'date not specified',
    nb: 'dato ikke angitt',
    nn: 'dato ikkje gjeve opp',
  },
  interestRateFixed: {
    en: 'Fixed interest rate',
    nb: 'Fastrente',
    nn: 'Fastrente',
  },
  interestRateFloating: {
    en: 'Floating interest rate',
    nb: 'Flytende rente',
    nn: 'Flytande rente',
  },
  interestRateFree: {
    en: 'Interest-free',
    nb: 'Rentefritt',
    nn: 'Rentefritt',
  },
  interestRateUnknown: {
    en: 'Unknown interest rate',
    nb: 'Ukjent rentetype',
    nn: 'Ukjend rentetype',
  },
  until: {
    en: 'until',
    nb: 'til',
    nn: 'til',
  },
  month: {
    en: 'month',
    nb: 'måned',
    nn: 'månad',
  },
  months: {
    en: 'months',
    nb: 'måneder',
    nn: 'månader',
  },
  contactConecto: {
    en: 'Contact information',
    nb: 'Kontaktinformasjon',
    nn: 'Kontaktinformasjon',
  },
  contactKredinor: {
    en: 'Contact Kredinor',
    nb: 'Kontakt Kredinor',
    nn: 'Kontakt Kredinor',
  },
  webSiteLabel: {
    en: 'Web site:',
    nb: 'Nettside:',
    nn: 'Nettside:',
  },
  overdueWarning: {
    en: 'Payments are overdue on this loan. Please check the invoices for further details.',
    nb: 'Det er manglende betalinger på dette lånet. Se fakturaoversikt for detaljer.',
    nn: 'Det manglar betalingar på dette lånet. Sjå fakturaoversikt for detaljar.',
  },
  debtCollectionWarningPart1: {
    en: 'Action is being taken to recover your debt. Please get in touch',
    nb: 'Ditt lån er sendt til inkasso. Ta kontakt på',
    nn: 'Lånet ditt er sendt til inkasso. Ta kontakt på',
  },
  debtCollectionWarningPart2: {
    en: 'to resolve the situation.',
    nb: 'for å finne en løsning.',
    nn: 'for å finne ei løysing.',
  },
  solveDebtCollectionHeader: {
    en: 'How do I resolve my debt collection case?',
    nb: 'Hvordan løser jeg inkassosaken?',
    nn: 'Korleis løyser eg inkassosaka?',
  },
  solveDebtCollection: {
    en: 'Your loan has been sent for debt collection due to unpaid invoice notices and debt collection bills. Please contact the debt collection company as soon as possible to resolve the situation. Please have your loan number available when contacting them.',
    nb: 'Ditt lån er sendt til inkasso på grunn av ubetalte fakturaer, purringer og inkassovarsler. Kontakt inkassobyrået så raskt som mulig for å finne en løsning. Ha lånenummer klart når du kontakter dem.',
    nn: 'Lånet ditt er sendt til inkasso på grunn av ubetalte fakturaer, purringar og inkassovarsel. Kontakt inkassobyrået så raskt som mogleg for å finne ei løysing. Ha lånenummer klart når du kontaktar dei.',
  },
  invoicesToPayHeader: {
    en: 'Which invoices should I have paid?',
    nb: 'Hvilke fakturaer skulle jeg ha betalt?',
    nn: 'Kva fakturaer skulle eg ha betalt?',
  },
  invoicesToPay: {
    en: 'The amount to pay for debt collection will be higher than the one specified on the original invoice and must be paid directly to the debt collection company.',
    nb: 'Beløp man betaler ved inkasso vil være høyere enn opprinnelig faktura, og betales direkte til inkassoselskap.',
    nn: 'Beløp ein betalar ved inkasso vil vere høgare enn beløp i opphavleg faktura, og må betalast direkte til inkassofirma.',
  },
  details: {
    en: 'Details',
    nb: 'Detaljer',
    nn: 'Detaljar',
  },
  transactionOverviewTitle: {
    en: 'Recent transactions',
    nb: 'Siste bevegelser',
    nn: 'Siste bevegelser',
  },
  // For Submitted Forms
  submittedFormsTitle: {
    en: 'Submitted on this agreement',
    nb: 'Innsendt på denne avtalen',
    nn: 'Innsendt på denne avtala',
  },

  // For Quick Links
  formActionsTitle: {
    en: 'Apply for a change',
    nb: 'Søk om endring',
    nn: 'Søk om endring',
  },
  formActionApplicationMaintenanceAmortizationDelay: {
    en: 'Installment free period',
    nb: 'Avdragsutsettelse',
    nn: 'Avdragsutsetjing',
  },
  formActionDeferralPayment: {
    en: 'Deferral of payment up to 3 months',
    nb: 'Betalingsutsettelse inntil 3 måneder',
    nn: 'Betalingsutsetjing inntil 3 månader',
  },
  formActionReleaseOfCollaterals: {
    en: 'Release of collaterals',
    nb: 'Frafall av sikkerheter',
    nn: 'Fråfall av sikkerheit',
  },
} as const;
