import { FormApplicationTypeEnum, SubmittedFormDto } from '../models/digibank-mypage.models';
import { FlattenedObject } from '../pages/Forms/components/FormPageWrapper';
import { apiDispatcher } from '../utils';

const baseUrl = '/api/v1/forms';
const baseUrl_v2 = '/api/v2/formsV2';

export const createForm = (applicationType: FormApplicationTypeEnum, formContext: any) => {
  return apiDispatcher<string>(`${baseUrl}/${applicationType}`, 'POST', formContext, {
    resolver: (resp) => resp.text(),
  });
};

// TODO probably need to replace PATCH with PUT as we always send the complete form data
export const saveForm = (formId: string, formValues: FlattenedObject[]) => {
  return apiDispatcher<string>(`${baseUrl_v2}/saveform?formId=${formId}`, 'PATCH', formValues, {
    resolver: (resp) => resp.text(),
  });
};

export const getExistingFormData = (formId: string) => {
  return apiDispatcher<any[]>(`${baseUrl_v2}/getForm?formId=${formId}`, 'GET');
};

export const deleteForm = (formId: string) => {
  return apiDispatcher(`${baseUrl_v2}/deleteForm?formId=${formId}`, 'DELETE');
};

export const uploadFile = async (formId: string, fieldName: string, file: any) => {
  const formData = new FormData();

  formData.append('file', file.file);
  formData.append('fieldName', fieldName);

  const upload = await fetch(`${baseUrl}/${formId}/UploadFile`, {
    method: 'POST',
    body: formData,
  });

  if (upload.status === 422) {
    const validations = (await upload.json()) as Array<string>;

    return {
      status: false,
      message: validations.join(', '),
    };
  }

  const uploadedFile = await upload.json();

  return {
    status: true,
    newId: uploadedFile.uploadName,
  };
};
export const uploadFile_v2 = async (formId: string, fieldName: string, file: any) => {
  const formData = new FormData();

  formData.append('file', file.file);
  formData.append('fieldName', fieldName);

  const upload = await fetch(`${baseUrl_v2}/${formId}/UploadFile`, {
    method: 'POST',
    body: formData,
  });

  if (upload.status === 422) {
    const validations = (await upload.json()) as Array<string>;

    return {
      status: false,
      message: validations.join(', '),
    };
  }

  const uploadedFile = await upload.json();

  return {
    status: true,
    newId: uploadedFile.uploadName,
  };
};

// TODO should properly propagate error, right now you can get statusCode 500 and deleted: true
export const deleteFile = async (formId: string, fileId: string) => {
  const result = {
    deleted: false,
    statusCode: 0,
  };

  const deleteResult = await fetch(`${baseUrl}/${formId}/DeleteFile/${fileId}`, {
    method: 'DELETE',
  });

  result.deleted = true;
  result.statusCode = deleteResult.status;

  return result;
};

export const deleteFile_v2 = async (formId: string, fileId: string) => {
  const deleteResult = await fetch(`${baseUrl_v2}/${formId}/DeleteFile/${fileId}`, {
    method: 'DELETE',
  });

  return { deleted: deleteResult.status.toString().startsWith('2'), statusCode: deleteResult.status };
};

export const downloadFile = async (formId: string, fileId: string) => {
  const download = await fetch(`${baseUrl}/${formId}/GetFile/${fileId}`);

  return await download.blob();
};

export const downloadAsset = async (asset: any) => {
  const download = await fetch(`${baseUrl}/DownloadAsset`, {
    body: JSON.stringify(asset),
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (download.status !== 200) return;

  return await download.blob();
};

export const getSubmittedForm = (formId: string) => {
  return apiDispatcher<SubmittedFormDto>(`${baseUrl}/${formId}/GetSubmitted`, 'GET');
};

export const getPageNumber = (formId: string, fieldName: string) => {
  return apiDispatcher<number>(`${baseUrl}/${formId}/GetPageByField/${fieldName}`, 'GET');
};

export const submitForm = (formId: string, htmlString: string) => {
  return apiDispatcher<string>(`${baseUrl_v2}/Submit?formId=${formId}`, 'POST', htmlString, { resolver: (resp) => resp.text() });
};
