export const releaseOfCollateralsLocalization = {
  releaseOfCollateralsIngress: {
    en: 'Release of collateral in an asset will typically cover the entire asset – e.g., the entire receivables pledge, the entire inventory pledge, the entire share pledge, or one or more specific farm and unit numbers.',
    nb: 'Frafall av sikkerhet i et formuesgode vil typisk omfatte hele formuesgodet – f.eks. hele fordringspantet, hele varelagerpantet, hele aksjepantet, eller et eller flere spesifikke gårds- og bruksnumre.',
    nn: 'Frafall av sikkerheit i ein formuesgjenstand vil typisk omfatte heile formuesgjenstanden – t.d. heile fordringspantet, heile varelagerpantet, heile aksjepantet, eller eitt eller fleire spesifikke gards- og bruksnummer.',
  },
  releaseOfCollateralInOneOrMoreAssets: {
    en: 'Release of collateral in one or more assets',
    nb: 'Frafall av sikkerhet i et eller flere formuesgoder',
    nn: 'Fråfall av sikkerheit i ein eller fleire formuesgjenstandar',
  },
  customerDetails: {
    en: 'Customer details',
    nb: 'Kundedetaljer',
    nn: 'Kundedetaljar',
  },
  account: {
    en: 'Account',
    nb: 'Kunde',
    nn: 'Kunde',
  },
  organizationNumber: {
    en: 'Organization number',
    nb: 'Organisasjonsnummer',
    nn: 'Organisasjonsnummer',
  },
  reasonForRelease: {
    en: 'Why is a release being requested?',
    nb: 'Hvorfor søkes det om frafall?',
    nn: 'Kvifor vert det søkt om fråfall?',
  },
  releaseOfCollaterals: {
    en: 'Release of collaterals',
    nb: 'Frafall av sikkerheter',
    nn: 'Fråfall av sikkerheit',
  },
  descriptionOfRelevantAssetsHeader: {
    en: 'Description of relevant assets',
    nb: 'Beskrivelse av aktuelle formuesgoder',
    nn: 'Skildring av aktuelle formuesgjenstandar',
  },
  whatKindOfAssetIsTheReleaseOfCollateralBeingRequestedFor: {
    en: 'What kind of asset is the release of collateral being requested for?',
    nb: 'Hva slags formuesgode skal det søkes frafall av sikkerhet for?',
    nn: 'Kva slags formuesgjenstand skal det søkast fråfall av sikkerheit for?',
  },
  realEstate: {
    en: 'Real estate',
    nb: 'Fast eiendom',
    nn: 'Fast eigedom',
  },
  other: {
    en: 'Other',
    nb: 'Annet',
    nn: 'Anna',
  },
  applicationAppliesToTheFollowingPropertiesAndParcels: {
    en: 'The application applies to the following properties and parcels',
    nb: 'Søknaden gjelder følgende eiendommer og parseller',
    nn: 'Søknaden gjeld følgjande eigedomar og parseller',
  },
  addMainRealEstate: {
    en: '+ Add main real estate',
    nb: '+ Legg til hovedeiendom',
    nn: '+ Legg til hovudeigedom',
  },
  applicationAppliesToTheFollowingAssets: {
    en: 'The application applies to the following assets',
    nb: 'Søknaden gjelder følgende formuesgoder',
    nn: 'Søknaden gjeld følgjande formuesgjenstandar',
  },
  describeTheCollateralForWhichReleaseIsSought: {
    en: 'Describe the collateral for which release is sought (e.g., inventory, receivables, operating equipment, etc.)',
    nb: 'Beskriv sikkerheten det søkes frafall for (for eksempel varelager, fordringer, driftstilbehør etc.)',
    nn: 'Skildre sikkerheita det vert søkt fråfall for (t.d. varelager, fordringar, driftsutstyr, osv.)',
  },
  mainRealEstate: {
    en: 'Main real estate',
    nb: 'Hovedeiendom',
    nn: 'Hovudeigedom',
  },
  farmNumber: {
    en: 'Farm number',
    nb: 'Gårdsnummer',
    nn: 'Gardsnummer',
  },
  propertyUnitNumber: {
    en: 'Property unit number',
    nb: 'Bruksnummer',
    nn: 'Bruksnummer',
  },
  findTheCorrectMunicipalityNumberHere: {
    en: 'Find the correct municipality number here: <br> <a href="https://www.regjeringen.no/no/tema/kommuner-og-regioner/kommunestruktur/nye-kommune-og-fylkesnummer-fra-1.-januar-2024/id2924701/" target="_blank" rel="noopener noreferrer">New municipal and county numbers from 2024 (regjeringen.no)</a>',
    nb: 'Finn riktig kommunenummer her: <br> <a href="https://www.regjeringen.no/no/tema/kommuner-og-regioner/kommunestruktur/nye-kommune-og-fylkesnummer-fra-1.-januar-2024/id2924701/" target="_blank" rel="noopener noreferrer">Nye fylkes- og kommunnenummer fra 2024 (regjeringen.no)</a>',
    nn: 'Finn riktig kommunenummer her: <br> <a href="https://www.regjeringen.no/no/tema/kommuner-og-regioner/kommunestruktur/nye-kommune-og-fylkesnummer-fra-1.-januar-2024/id2924701/" target="_blank" rel="noopener noreferrer">Nye fylkes- og kommunnenummer frå 2024 (regjeringen.no)</a>',
  },
  municipalityNumber: {
    en: 'Municipality number',
    nb: 'Kommunenummer',
    nn: 'Kommunenummer',
  },
  addSubdividedParcel: {
    en: '+ Add subdivided parcel',
    nb: '+ Legg til fradelt parsell',
    nn: '+ Legg til frådelt parsell',
  },
  subdividedParcel: {
    en: 'Subdivided parcel',
    nb: 'Fradelt parsell',
    nn: 'Frådelt parsell',
  },
  subdividedArea: {
    en: 'Subdivided area',
    nb: 'Utskilt areal',
    nn: 'Utskilt areal',
  },
  totalArea: {
    en: 'Total subdivided area',
    nb: 'Totalt utskilt areal',
    nn: 'Totalt utskilt areal',
  },
  whatIsTheSubdividedAreaUsedFor: {
    en: 'What is the subdivided area used for?',
    nb: 'Hva skal det utskilte areal brukes til?',
    nn: 'Kva skal det utskilte arealet brukast til?',
  },
  whatIsTheSubdividedAreaUsedForPlaceholder: {
    en: 'ground properties, owner sections, etc.',
    nb: 'grunneiendommer, eierseksjoner, etc.',
    nn: 'grunn-eigedomar, eigarseksjonar, etc.',
  },
  describeConstructionOnSubdividedParcel: {
    en: 'Describe the construction on the subdivided parcel',
    nb: 'Beskriv bebyggelsen på den fradelte parsellen',
    nn: 'Skildre bebyggelsen på den frådelte parsellen',
  },
  construction: {
    en: 'Construction',
    nb: 'Bebyggelse',
    nn: 'Bebyggelse',
  },
  isThereConstructionOnTheSubdividedParcel: {
    en: 'Is there construction on the subdivided parcel?',
    nb: 'Er det bebyggelse på den fradelte parsellen?',
    nn: 'Er det bebyggelse på den frådelte parsellen?',
  },
  whyIsThereConstructionOnTheSubdividedParcel: {
    en: 'Why is there construction on the subdivided parcel?',
    nb: 'Hvorfor er det bebyggelse på den fradelte parsellen?',
    nn: 'Kvifor er det bebyggelse på den frådelte parsellen?',
  },
  titleHolder: {
    en: 'Title holder',
    nb: 'Hjemmelshaver',
    nn: 'Heimelshavar',
  },
  doesSubdividedParcelHaveNewTitleHolder: {
    en: 'Does the subdivided parcel have a new title holder?',
    nb: 'Har fradelt parsell ny hjemmelshaver?',
    nn: 'Har frådelt parsell ny heimelshavar?',
  },
  newTitleHolderFullName: {
    en: 'New title holder (full name)',
    nb: 'Ny hjemmelshaver (fullt navn)',
    nn: 'Ny heimelshavar (fullt namn)',
  },
  saleSum: {
    en: 'Sale sum',
    nb: 'Salgssum',
    nn: 'Salsum',
  },
  whatIsTheSaleSumFor: {
    en: 'What is the sale sum for?',
    nb: 'Hva skal salgssummen brukes til?',
    nn: 'Kva skal salsummen brukast til?',
  },
  attachments: {
    en: 'Attachments',
    nb: 'Vedlegg',
    nn: 'Vedlegg',
  },
  uploadTaxReportOrAnnualAccounts: {
    en: 'Upload tax report (agriculture) or annual accounts',
    nb: 'Last opp næringsoppgave/skattemelding (landbruk) eller årsregnskap',
    nn: 'Last opp næringsoppgåve/skattemelding (landbruk) eller årsrekneskap',
  },
  landTransferOrExchangeInAgriculture: {
    en: 'Does it concern land transfer or exchange within agriculture?',
    nb: 'Gjelder det arealoverføring eller makebytte innen landbruk?',
    nn: 'Gjeld det arealoverføring eller makebyte innan landbruk?',
  },
  uploadMunicipalDecisionForLandTransferOrExchange: {
    nb: 'Last opp kommunens vedtak for arealoverføring/makebytte',
    en: `Upload the municipality's decision for land transfer/exchange`,
    nn: 'Last opp kommunens vedtak for arealoverføring/makebyte',
  },
  otherInformationOptional: {
    en: 'Other information (optional)',
    nb: 'Andre opplysninger (valgfritt)',
    nn: 'Andre opplysningar (valfritt)',
  },
  summary: {
    en: 'Summary and submission',
    nb: 'Oppsummering og innsending',
    nn: 'Oppsummering og innsending',
  },
  dialogTitle: {
    en: 'Do you want to delete this subdivided parcel with all registered information?',
    nb: 'Vil du slette denne fradelte parsellen med all registrert informasjon?',
    nn: 'Vil du slette denne frådelte parsellen med all registrert informasjon?',
  },
  dialogConfirmText: {
    en: 'Yes, delete subdivided parcel',
    nb: 'Ja, slett fradelt parsell',
    nn: 'Ja, slett frådelt parsell',
  },
  dialogCancelText: {
    en: 'Cancel',
    nb: 'Avbryt',
    nn: 'Avbryt',
  },
  mainRealEstateDialogTitle: {
    en: 'Deleting the main real estate will also delete all registered information about associated parcels in the application. Do you want to delete this main real estate?',
    nb: 'Sletting av hovedeiendom vil også slette all registrert informasjon om tilhørende parseller i søknaden. Vil du slette denne hovedeiendommen?',
    nn: 'Sletting av hovudeigedom vil også slette all registrert informasjon om tilhøyrande parseller i søknaden. Vil du slette denne hovudeigedomen?',
  },
  mainRealEstateDialogConfirmText: {
    en: 'Yes, delete main real estate',
    nb: 'Ja, slett hovedeiendom',
    nn: 'Ja, slett hovudeigedom',
  },
  confirmDetails: {
    en: 'Confirm that the information is correct',
    nb: 'Bekreft at opplysningene stemmer',
    nn: 'Stadfest at opplysningane stemmer',
  },
  confirmDetailsOption: {
    en: 'I confirm that this information is correct',
    nb: 'Jeg bekrefter at disse opplysningene stemmer',
    nn: 'Eg stadfestar at desse opplysningane stemmer',
  },
  fileTypesAllowed: {
    en: 'You can upload the following file types: .pdf, .docx, .xlsx, .odt, and .ods',
    nb: 'Du kan laste opp følgende filtyper: .pdf, .docx, .xlsx, .odt og .ods',
    nn: 'Du kan laste opp følgjande filtypar: .pdf, .docx, .xlsx, .odt og .ods',
  },
  processingCost: {
    en: 'Processing cost',
    nb: 'Behandlingskostnad',
    nn: 'Behandlingskostnad',
  },
  processingCostDetails: {
    en: 'Processing a maintenance case incurs a cost from 1250,- NOK per change per loan account. If the cost exceeds 1250,- NOK, Innovation Norway will contact you with information about the fee. You must approve this fee before we process the case. The fee will appear in the decision letter and will be executed on the next invoice.',
    nb: 'Behandling av en vedlikeholdssak medfører en kostnad fra 1250,- NOK per endring per lånekonto. I de tilfeller kostnaden blir høyere enn 1250,- NOK vil du bli kontaktet av Innovasjon Norge med informasjon om størrelse på gebyr. Du vil da måtte godkjenne dette gebyret før vi behandler saken. Gebyr vil fremkomme i vedtaksbrevet, og effektueres ved neste faktura.',
    nn: 'Behandling av ein vedlikehaldssak medfører ein kostnad frå 1250,- NOK per endring per lånekonto. I dei tilfella kostnaden blir høgare enn 1250,- NOK vil du bli kontakta av Innovasjon Noreg med informasjon om storleiken på gebyret. Du må då godkjenne dette gebyret før vi behandlar saka. Gebyret vil framkome i vedtaksbrevet, og effektuerast ved neste faktura.',
  },
} as const;
